.parser-li{
    line-height: 1.8em;
    margin-top: 1em;
    list-style: none;
}

.parser-li:last-child{
    margin-bottom: 1em;
}

.parser-a{
    color: var(--accent);
}

.parser-a:hover{
    text-decoration: underline;
}

.parser-li::before {
    content: "";
    background: var(--accent);
    font-weight: bold;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    transform: translateY(-2px);
}

.parser-img{
    border-radius: 20px;
    /*border: 1px solid lightgrey;*/
    object-fit: contain;
    /*max-height: 300px;*/
    width: 100%;
    max-height: calc(100vh - 100px);
}

.parser-code{
    background: #f6f6f6;
    padding: 20px;
    border-left: 3px solid gray;
    /*border-left-color: var(--accent);*/
    margin: 0;
    display: block;
}

.parser-blockquote{
    background: #f6f6f6;
    padding: 20px;
    border-left: 3px solid gray;
    border-left-color: var(--accent);
    margin: 0;
}

#parser h4{
    margin-top: 2.5em;
    margin-bottom: 1em;
}

#parser h5{
    margin-top: 2em;
    margin-bottom: 1em;
}

#parser img {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
