/*Menu animations*/
#menu_selector {
    display: grid;
    gap: 7px
}

#menu_selector > div{
    background: #000;
    width: 30px;
    height: 3px;
}

#selector_1, #selector_3{
    transform-origin: 3% 50%
}

#mobile-menu-container{
    display: none;
    z-index: 2;
    background: #fff;
  /*  background: rgba(255,255,255,0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);*/
    position: absolute;
    top: 62px;
    left: 0;
    height: calc(100vh - 62px);
    overflow-y: auto;
    width: 100% ;
}

#mobile-events {
    padding: 6px 22px;
    position: fixed;
    right: 20px;
    background: #fff;
    top: calc(100vh - 130px);
}

/*.sub-menu-item::before {
    content: "";
    background: var(--accent);
    font-weight: bold;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    transform: translateY(-2px);
}*/

/*.sub-menu::before{
    content: "";
    width: 150%;
    height: 150%;
    background: rgba(255, 0, 0, 0.68);
    position: absolute;
    transform: translateY(-40%) translateX(-30%);
    z-index: 500;
}*/

@keyframes selector_1_open{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(45deg);
    }
}

@keyframes selector_1_close{
    0%{
        transform: rotate(45deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes selector_3_open{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-45deg);
    }
}

@keyframes selector_3_close{
    0%{
        transform: rotate(-45deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes open_list{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes close_list{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
