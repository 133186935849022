:root{
    --accent: #ee3f45
}

p{
    font-size: 18px;
    font-family: Grotesque, sans-serif;
    /*white-space: pre-line;*/
    margin-block: unset;
    line-height: 1.8em;
}

.hover{
    transition: all 0.5s;
}

.hover:hover{
    color: var(--accent)
}

.MuiTypography-root > span {
    word-break: break-word;
}

@keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-120%); }
}
