#qualification ul{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1em 0 2em 0;
    padding: 0;
}

#qualification li{
    background: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    margin: 0;
}

#qualification li::before{
    display: none;
}

#qualification #pretty{
    margin-top: 30px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    line-height: 1.6em;
}

#pluses ul{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

#pluses li{
    background: #f6f6f6;
    border-radius: 20px;
    padding: 7px 20px;
    margin: 0;
}

#pluses li::before{
    display: none;
}

#individual li:first-child{
    margin-top: 0;
}

#individual{
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: start;
    gap: 60px;
    border-bottom: 3px solid lightgray;
    padding-bottom: 45px;
    padding-top: 35px;
    margin-top: 20px;
}

#individual:first-child{
    padding-top: 15px;
}

#individual:last-child{
    border-bottom: none;
    padding-bottom: 15px;
}

#individual h4{
    margin-top: 0;
    font-size: 30px;
    line-height: 1.5em;
}

@media (max-width: 1200px) {
    #pluses li{
        padding: 10px 20px;
    }
    #qualification li{
        padding: 10px 20px;
    }

    #qualification ul{
        gap: 22px;
    }

    #pluses ul{
        gap: 22px;
    }

    #individual{
        grid-template-columns: 1fr;
        gap: 20px;
    }

}
