@keyframes marquee {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.marque {
    -moz-animation: marquee 10s linear infinite;
    -webkit-animation: marquee 10s linear infinite;
    animation: marquee 10s linear infinite;
}
