.fadein {
    /*transition: opacity 1.5s ease;*/
    animation-duration: 3500ms;
    animation-name: fadein;
    display: inline-block;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes fadein {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }
    10% {
        transform: translateY(0px);;
        opacity: 1;
    }
    90% {
        transform: translateY(0px);;
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}
